@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  font-family: 'Cairo', sans-serif !important;
}

body {
  background-color: #f1f4f6 !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.muiltr-1l8j5k8 {
  left: 0 !important;
}

/* react code input  */

.react-tel-input .form-control {
  width: 100% !important;
}

.react-code-input {
  display: flex !important;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.react-code-input input {
  width: 80px !important;
  font-size: 20px !important;
  text-align: center;
  padding: 10px 0px;
}

.CircularProgressbar-text {
  font-size: 28px !important;
  font-weight: bold;
}

/* select currency */
.currency .MuiSelect-select.MuiSelect-outlined {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  outline: 0px !important;
}

.currency select {
  border: 0px;
}

/* home banner overlay */
.overlay {
  position: relative;
}

.overlay::before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
}

/* autocomplete*/
.muirtl-1x5vv0-MuiGrid-root>.MuiGrid-item {
  padding-top: 0px !important;
}

/* bullet */
.swiper-wrapper {
  margin-bottom: 50px;
}

.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  border: 2px solid #d4d4d4 !important;
  background-color: #ffffff !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet:hover {
  background-color: #5BC0F8 !important;
  transition: .5s;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  -ms-transition: .5s;
  -o-transition: .5s;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #5BC0F8 !important;
}

/* country select */
.country-select {
  width: 100% !important;
  height: 50px !important;
}